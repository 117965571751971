import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const epoPorandad = () => (
  <Layout>
    <SEO title="Epopõrandad" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>EPOPÕRANDAD</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link to="/epoporandad/antistaatilised/">
          <div className="epo antistaatilised">
            <h2>Antistaatilised</h2>
            <div className="epo-img epo-img1"></div>
          </div>
        </Link>
        <Link to="/epoporandad/epohoordemass/">
          <div className="epo epohoordemass">
            <h2>epohõõrdemass</h2>
            <div className="epo-img epo-img2"></div>
          </div>
        </Link>
        <Link to="/epoporandad/isetasanduv/">
          <div className="epo isetasanduv-epomasskate">
            <h2>isetasanduv epomasskate</h2>
            <div className="epo-img epo-img3"></div>
          </div>
        </Link>
        <Link to="/epoporandad/treppidele/">
          <div className="epo treppidele">
            <h2>treppidele</h2>
            <div className="epo-img epo-img4"></div>
          </div>
        </Link>
        <Link to="/epoporandad/dekoratiivporandad/">
          <div className="epo dekoratiivporandad">
            <h2>dekoratiivpõrandad</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
        <Link to="/epoporandad/lasketiiru/">
          <div className="epo lasketiiru">
            <h2>Lasketiiru põrandad</h2>
            <div className="epo-img epo-img6"></div>
          </div>
        </Link>
      </div>

      <div className="epoporandad-text">
        <p>
          Jagame Teiega meeleldi oma teadmisi, kogemusi, oskusi epopõrandate
          alal. Pakume Teile kvaliteetseid epopõrandakatteid suurtest
          tööstusprojektidest kuni eksklusiivprojektideni välja.
          Baasmaterjalidena kasutame Caparol GmbH (Saksamaa), Epokate (Eesti)
          jaTikkurila (Soome) tooteid.
        </p>
      </div>
    </div>
  </Layout>
)

export default epoPorandad
